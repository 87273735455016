import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
var Unathorized = function () {
    return (React.createElement(Grid, { container: true, height: '100vh', direction: 'column', justifyContent: 'center' },
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: 'h5', className: 'welcome-title w-800', marginTop: '30px' }, "It seems you have no account")),
        React.createElement(Grid, { item: true },
            React.createElement(Typography, { variant: 'h5', className: 'welcome-text w-600', marginTop: '15px' },
                "Type ",
                React.createElement("span", { className: 'link-text' }, "/start"),
                " in bot"))));
};
export default Unathorized;
