export var ru = {
    loginDescription: 'Высококачественные знакомства в Telegram. Присоединяйтесь к сообществу амбициозных и красивых людей.',
    create_profile_button: 'Создать профиль',
    login: 'Вход',
    create_profile_title: 'Создать профиль',
    name_input_placeholder: 'Ваше имя',
    description_input_placeholder: 'О вас',
    description_input_example: 'Пример: дизайнер из Дубая. Люблю серфинг...',
    profile_creation_continue_button: 'Продолжить',
    personal_info_title: 'Личная информация',
    date_of_birth: 'Дата рождения',
    date_of_birth_choose_select: 'Выберите дату',
    gender: 'Пол',
    login_description: 'Высококачественные знакомства в Telegram. Присоединяйтесь к сообществу амбициозных и красивых людей.',
    select: 'Выбрать',
    location: 'Местоположение',
    city_input_title: 'Ваш город',
    previous_city_input_title: 'Предыдущий город',
    looking_for_title: 'Я ищу',
    age_range: 'Возрастной диапазон',
    social_title: 'Соцсети',
    optional: 'опционально',
    cancel: 'Отменить',
    instagram_input_placeholder: 'instagram.com/username',
    tiktok_input_placeholder: 'tiktok.com/@username',
    add_profile_photos_title: 'Добавить фото профиля',
    main_photo_title: 'Главное фото',
    at_least_3: 'Загрузите как минимум три фотографии.',
    at_least_info: 'На фотографиях должно быть видно только вас — ваше лицо и тело. Убедитесь, что на снимке нет других людей. Не загружайте нерелевантные изображения или случайные вещи.',
    photo_upload_note: 'Не менее 3 фотографий. Краткое описание допустимых типов фотографий и ограничений (например, без наготы, лицо пользователя должно быть четко видно).',
    x_of_y: '0 из 9',
    profile_under_review_title: 'Ваш профиль находится на рассмотрении',
    profile_under_review_info: 'После проверки вы получите доступ ко всем привилегиям Queens:',
    benefit_1: 'Индивидуальная оценка совместимости',
    benefit_2: 'Получайте награды за качественный профиль',
    benefir_3: 'Все профили проверены',
    got_it: 'Понятно',
    quiz_complete_compatability: 'Пройдите тест на совместимость',
    quiz_description: 'Получите индивидуальную оценку совместимости на основе своих ответов',
    start_quiz: 'Начать тест',
    zodiac: 'Знак зодиака',
    tasks: 'Задачи',
    refs: 'Рефы',
    social_tasks: 'Социальные задания',
    join_telegram_chanel_task_title: 'Присоединяйтесь к каналу Queens TG',
    start_task: 'Начать',
    instagram_follow_task_title: 'Подписывайтесь на Instagram Queens',
    daily_tasks: 'Ежедневные задачи',
    start_farming: 'Начать фарминг',
    task_completion: 'Вы получили X энергии',
    refs_total_earnings: 'Общий доход',
    ref_first_level_earn_percent: '10% ваших друзей',
    ref_second_level_earn_percent: '+2,5% от их рефералов',
    invite_friend: 'Пригласить друга',
    invite: 'Пригласить',
    invite_friend_title: 'Пригласите друзей и получите',
    invite_firned_info: 'Получите 10% от заработка ваших друзей и дополнительный бонус 2,5% от заработка их друзей.',
    send_invite: 'Отправить приглашение',
    copy_invite_link: 'Скопировать ссылку',
    invited_friends: 'Приглашенные друзья',
    edit_profile: 'Редактировать профиль',
    looking_for: 'Я ищу',
    contact_support: 'Служба поддержки',
    faq: 'Часто задаваемые вопросы',
    privacy_policy: 'Политика конфиденциальности',
    pause_profile: 'Приостановить мой профиль',
    mutual: 'Взаимноcть',
    inbox: 'Входящие',
    go_to_chat: 'Перейти в чат',
    unlock_inbox_profile: 'Разблок.',
    profile_unlocked: 'Профиль разблокирован',
    open_profile: 'Открыть профиль',
    it_is_a_match: 'Это совпадение!',
    match_description: 'Вы с Гленом нравитесь друг другу',
    maybe_later: 'Может быть позже',
    matchTitle: 'Это совпадение!',
    matchInfo: 'Вы и {{name}} понравились друг другу',
    goToChat: 'Перейти в чат',
    maybeLater: 'Может быть позже',
    crownMatchTitle: 'Наденьте корону!',
    crownMatchInfo: 'Поздравляем! Теперь вы можете начать общение с пользователем {{name}}',
    popularLocations: 'Популярные места',
    searchResults: 'Результаты поиска',
    mutualEmptyTitle: 'Взаимные связи',
    inboxEmptyTitle: 'Реакции во входящих сообщениях',
    mutualEmptyInfo: 'Когда кто-то отправит реакцию на ваш профиль, мы уведомим вас',
    unlockProfileMsg: 'Профиль разблокирован',
    unlock: 'Разблокировать',
    openProfile: 'Открыть профиль',
    youveGot: 'У вас есть',
    youGot: 'Вы получили',
    start: 'Начать',
    about: 'О вас',
    claim: 'Получить',
    checkBackLater: 'Проверьте позже',
    noOne: 'Поблизости от вас нет новых людей',
    male: 'Мужчина',
    female: 'Женский',
    nonBinary: 'Небинарный (другое)',
    claimReward: 'Получить награду',
    profilePaused: 'Профиль приостановлен',
    unpause: 'Возобновить',
    profileUnpaused: 'Профиль восстановлен',
    completed: 'Завершено',
    age_restrict: 'Вам должно быть 18 лет или больше, чтобы продолжить.',
    needToSubscribe: 'Вам нужно подписаться на канал',
    updating: 'Обновление профиля...',
    confirm: 'Подтвердить',
    aries: 'Овен',
    taurus: 'Телец',
    gemini: 'Близнецы',
    cancer: 'Рак',
    leo: 'Лео',
    virgo: 'Дева',
    libra: 'Весы',
    scorpio: 'Скорпион',
    sagittarius: 'Стрелец',
    capricorn: 'Козерог',
    aquarius: 'Водолей',
    pisces: 'Рыбы',
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
    question_1: 'Что это за приложение?',
    question_1_answer: 'Это уникальное приложение для знакомств, интегрированное с Telegram. Вместо традиционного свайпа "Да/Нет", приложение позволяет вам взаимодействовать с помощью различных реакций, которые выражают ваши эмоции и предпочтения.',
    question_2: 'Какие реакции я могу использовать в приложении?',
    question_2_answer: 'В приложении доступны пять типов реакций, каждая из которых имеет определённую стоимость: Корона – стоит 1 алмаз, Лайк – стоит 10 молний, Огонь – стоит 10 молний, Смех – стоит 1 молнию, Рукопожатие – стоит 1 молнию. Вы можете выбрать любую из этих реакций для взаимодействия с другими пользователями.',
    question_3: 'Как заработать и тратить алмазы и молнии?',
    question_3_answer: 'Вы можете зарабатывать алмазы и молнии, проявляя активность в приложении, выполняя определённые задания и приглашая друзей присоединиться к приложению. Эти ресурсы используются для отправки реакций другим пользователям или для разблокировки дополнительных функций.',
    question_4: 'Как я могу общаться с другими пользователями?',
    question_4_answer: 'В этой версии приложения нет встроенного чата. Однако, когда у вас происходит совпадение с другим пользователем, вам будет показано его имя пользователя в Telegram, что позволит начать общение напрямую через Telegram.',
    question_5: 'Что происходит, когда я получаю положительные реакции?',
    question_5_answer: 'Когда вы получаете положительные реакции, такие как Корона, Лайк или Огонь, они отображаются в разделе реакций приложения. Вы увидите, кто отправил реакцию, а взаимные положительные реакции будут выделены в отдельном разделе. Если вы оба отправили друг другу положительные реакции, вы можете начать общение через Telegram.',
    question_6: 'Появляются ли нейтральные реакции в приложении?',
    question_6_answer: 'Нет, нейтральные реакции, такие как Смех и Рукопожатие, не отображаются в этой версии приложения. Отображаются только положительные реакции.',
    question_7: 'Нужно ли загружать фотографии для регистрации?',
    question_7_answer: 'Да, для завершения регистрации необходимо загрузить минимум три фотографии. Эти изображения должны чётко показывать ваше лицо и соответствовать правилам контента приложения.',
    question_8: 'Могу ли я редактировать свой профиль после регистрации?',
    question_8_answer: 'Да, после регистрации вы можете редактировать информацию профиля и обновлять фотографии. Для этого перейдите в раздел профиля и внесите необходимые изменения.',
    question_9: 'Как работает реферальная система?',
    question_9_answer: 'Вы можете зарабатывать алмазы, приглашая друзей присоединиться к приложению. Награды начисляются после нажатия кнопки "Старт" в разделе рефералов, и вы получите бонусы за каждого друга, который зарегистрируется по вашей ссылке.',
    question_10: 'Как работает система модерации?',
    question_10_answer: 'После регистрации ваш профиль отправляется на модерацию. Пока профиль проверяется, вы можете пользоваться приложением без ограничений. Если ваш профиль одобрен, вы продолжите пользоваться приложением как обычно. Если профиль отклонён, вам будет предложено внести изменения и отправить его на повторную проверку.',
    question_11: 'Как я буду получать уведомления?',
    question_11_answer: 'Все уведомления будут приходить через Telegram-бота, связанного с вашим аккаунтом. Вы будете получать информацию о новых совпадениях, поступивших реакциях и других важных событиях в приложении.',
    effective_date: 'Дата вступления в силу: 20.08.2024',
    policy: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    policy_1: 'Queens Dating, управляемая компанией QUEENS-DATING LIMITED (мы, нас, наш или Компания), обязуется защищать вашу конфиденциальность и обеспечивать безопасность ваших персональных данных. Данная Политика конфиденциальности описывает, как мы собираем, используем, делимся и защищаем вашу личную информацию при использовании приложения Queens Dating (далее Приложение или Сервис). Используя Сервис, вы соглашаетесь с данной Политикой конфиденциальности и даете согласие на сбор, использование и раскрытие вашей информации, как указано в этом документе.',
    policy_2: 'Мы понимаем важность вашей конфиденциальности и стремимся ответственно и безопасно обращаться с вашей личной информацией.',
    policy_3: '1. Информация, которую мы собираем',
    policy_4: 'Мы собираем различные типы информации для предоставления и улучшения нашего Сервиса, включая:',
    policy_5: '1.1 Личная информация',
    policy_6: '- Данные профиля: при создании учетной записи мы собираем личную информацию, такую как ваше имя, возраст, пол, адрес электронной почты, номер телефона, фото профиля, интересы, сексуальную ориентацию и предпочтения в отношениях.',
    policy_7: '- Данные о местоположении: мы собираем информацию о геолокации, чтобы помочь вам связываться с пользователями поблизости. Это включает данные в реальном времени с вашего мобильного устройства, если вы разрешаете доступ к службам определения местоположения.',
    policy_8: '- Данные о коммуникациях: это включает любые сообщения, голосовые записи или обмен мультимедийными файлами, которыми вы обмениваетесь с другими пользователями через Сервис.',
    policy_9: '1.2 Поведенческие данные и данные об использовании',
    policy_10: 'Мы собираем информацию о том, как вы взаимодействуете с нашим Приложением:',
    policy_11: '- Информация о действиях: включает данные о функциях, которые вы используете, пользователях, с которыми вы взаимодействуете, и ваши предпочтения в Приложении, такие как свайпы, совпадения и взаимодействия.',
    policy_12: '- Информация об устройстве: мы можем собирать данные об устройстве, включая модель оборудования, операционную систему, версию приложения, идентификаторы устройства, такие как IP-адрес, MAC-адрес, тип браузера и информацию о мобильной сети.',
    policy_13: '1.3 Автоматически собираемые данные',
    policy_14: 'Мы можем автоматически собирать определенные данные, включая:',
    policy_15: '- Логи данных: при взаимодействии с нашим Сервисом мы собираем серверные логи, которые могут включать ваш IP-адрес, дату и время использования, посещенные страницы, сбои приложения и другую системную активность.',
    policy_16: "- \u0424\u0430\u0439\u043B\u044B cookie \u0438 \u0430\u043D\u0430\u043B\u043E\u0433\u0438\u0447\u043D\u044B\u0435 \u0442\u0435\u0445\u043D\u043E\u043B\u043E\u0433\u0438\u0438: \u043C\u044B \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u043C \u0444\u0430\u0439\u043B\u044B cookie \u0438 \u0430\u043D\u0430\u043B\u043E\u0433\u0438\u0447\u043D\u044B\u0435 \u0442\u0435\u0445\u043D\u043E\u043B\u043E\u0433\u0438\u0438 \u0434\u043B\u044F \u0430\u043D\u0430\u043B\u0438\u0437\u0430 \u0442\u0435\u043D\u0434\u0435\u043D\u0446\u0438\u0439, \u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F \u0441\u0430\u0439\u0442\u043E\u043C, \u043E\u0442\u0441\u043B\u0435\u0436\u0438\u0432\u0430\u043D\u0438\u044F \u043F\u0435\u0440\u0435\u043C\u0435\u0449\u0435\u043D\u0438\u0439 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u043F\u043E \u0441\u0430\u0439\u0442\u0443 \u0438 \u0441\u0431\u043E\u0440\u0430 \u0434\u0435\u043C\u043E\u0433\u0440\u0430\u0444\u0438\u0447\u0435\u0441\u043A\u043E\u0439 \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u0438.",
    policy_17: '2. Как мы используем вашу информацию',
    policy_18: 'Мы используем собранную информацию для различных целей, чтобы улучшить ваш опыт в Queens Dating, включая:',
    policy_19: '- Для предоставления Сервиса: ваша информация помогает нам предоставлять, управлять и поддерживать наш Сервис, включая возможность связывать вас с другими пользователями в зависимости от ваших предпочтений и местоположения.',
    policy_20: '- Для персонализации вашего опыта: мы используем вашу информацию для персонализации вашего опыта, например, предлагая потенциальных совпадений, отображая контент и улучшая рекомендации.',
    policy_21: '- Для улучшения Сервиса: мы анализируем данные, чтобы понимать тенденции и шаблоны использования и улучшать функции и функциональность нашего Сервиса.',
    policy_22: '- Для маркетинга и промоакций: мы можем использовать вашу личную информацию для доставки рекламного контента и рекламы, которая может быть вам интересна, как в Приложении, так и по электронной почте.',
    policy_23: '- Для связи с вами: мы можем использовать вашу информацию для отправки вам обновлений, уведомлений, сообщений безопасности и сообщений поддержки.',
    policy_24: '- Для обеспечения безопасности: мы используем информацию для мониторинга активности пользователей, выявления и предотвращения мошенничества и обеспечения соблюдения наших условий.',
    policy_25: '3. Передача вашей информации',
    policy_26: 'Мы ценим вашу конфиденциальность и передаем вашу информацию только в случаях, описанных в этой политике:',
    policy_27: '3.1 С другими пользователями',
    policy_28: 'Используя Сервис, вы соглашаетесь с тем, что мы можем передавать вашу профильную информацию, такую как имя, возраст, фотографии и другие данные профиля другим пользователям. Будьте внимательны к тому, какую информацию вы раскрываете, так как вы несете ответственность за ее передачу другим.',
    policy_29: '3.2  С поставщиками услуг',
    policy_30: 'Мы привлекаем надежных сторонних поставщиков услуг для выполнения определенных функций, таких как хостинг, анализ данных, поддержка клиентов, обработка платежей и реклама. Эти поставщики обязаны защищать вашу информацию и использовать ее только для указанных нами целей.',
    policy_31: '3.3 В случае передачи бизнеса',
    policy_32: 'Если мы пройдем через слияние, приобретение, реорганизацию или продажу активов, ваша информация может быть передана в рамках такой сделки. Мы уведомим вас о любой такой передаче и новых правилах конфиденциальности.',
    policy_33: '3.4 Для соблюдения законов',
    policy_34: 'Мы можем передавать вашу информацию по требованию закона, например, в ответ на повестку, запрос от правительства или другие юридические процессы. Мы также можем передавать информацию для защиты прав, собственности или безопасности Queens Dating, наших пользователей или других лиц.',
    policy_35: '4. Международная передача данных',
    policy_36: 'Ваша личная информация может быть передана и храниться на серверах, расположенных за пределами вашей юрисдикции, в том числе в странах, где законы о защите данных могут отличаться от законов вашей страны. Используя наш Сервис, вы соглашаетесь на такие передачи. Мы принимаем меры для обеспечения того, чтобы ваша информация обрабатывалась безопасно и в соответствии с данной Политикой конфиденциальности, где бы она ни находилась.',
    policy_37: '5. Хранение данных',
    policy_38: 'Мы будем хранить вашу личную информацию только до тех пор, пока это необходимо для предоставления вам нашего Сервиса и соблюдения наших юридических обязательств. Если вы закроете свою учетную запись, мы удалим или анонимизируем вашу личную информацию, если иное не требуется по закону или для законных деловых целей.',
    policy_39: '6. Ваши права и выбор',
    policy_40: 'В зависимости от вашего местоположения и применимых законов вы можете обладать следующими правами:',
    policy_41: '- Доступ к вашей информации: вы можете запросить доступ к вашим персональным данным и получить копию информации, которую мы храним о вас.',
    policy_42: '- Исправление информации: вы имеете право запросить исправление любой неточной или неполной информации.',
    policy_43: '- Удаление информации: при определенных обстоятельствах вы можете запросить удаление ваших персональных данных. Обратите внимание, что мы можем хранить определенную информацию, как это требуется по закону.',
    policy_44: '- Ограничение обработки: вы можете запросить ограничение обработки вашей личной информации в определенных ситуациях.',
    policy_45: '- Переносимость данных: вы имеете право запросить передачу ваших данных другому поставщику услуг.',
    policy_46: '- Отзыв согласия: если мы полагаемся на ваше согласие для обработки данных, вы можете отозвать его в любое время.',
    policy_47: 'Чтобы воспользоваться любым из этих прав, свяжитесь с нами по [Вставить контактную информацию].',
    policy_48: '7. Безопасность данных',
    policy_49: 'Мы стремимся поддерживать безопасность вашей информации. Мы внедряем различные технические и организационные меры для защиты ваших данных от несанкционированного доступа, изменения, раскрытия или уничтожения. Однако никакие меры безопасности не могут обеспечить абсолютную безопасность, и мы не можем гарантировать полную безопасность вашей информации.',
    policy_50: '8. Конфиденциальность детей',
    policy_51: 'Наш Сервис не предназначен для лиц младше 18 лет. Мы сознательно не собираем персонально идентифицируемую информацию от детей младше 18 лет. Если мы обнаружим, что случайно собрали такую информацию, мы предпримем меры по ее удалению как можно скорее. Если вы считаете, что у нас может быть информация о ребенке, свяжитесь с нами.',
    policy_52: '9. Изменения в Политике конфиденциальности',
    policy_53: 'Мы можем периодически обновлять данную Политику конфиденциальности, чтобы отразить изменения в наших практиках или юридические требования. Мы уведомим вас о любых значительных изменениях, разместив новую Политику конфиденциальности на этой странице и обновив дату вступления в силу. Мы рекомендуем вам периодически просматривать эту Политику конфиденциальности для ознакомления с обновлениями.',
    policy_54: '10. Связаться с нами',
    policy_55: 'Если у вас есть вопросы или опасения по поводу данной Политики конфиденциальности или вы хотите воспользоваться своими правами, свяжитесь с нами по указанным контактным данным.',
};
