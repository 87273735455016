import * as Sentry from '@sentry/react';
import WebApp from '@twa-dev/sdk';
import { toast } from 'react-toastify';
export var pushToSentry = function (args, api, extraOptions, result, noToast) {
    var _a, _b, _c, _d, _e, _f, _g;
    if ((api === null || api === void 0 ? void 0 : api.endpoint) == 'swipe') {
        return;
    }
    if (((_b = (_a = result === null || result === void 0 ? void 0 : result.error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.cause) !== 'CHANEL_SUBSCRIPTION_NEEDED' && !noToast) {
        showToast('Something went wrong');
    }
    Sentry.captureException(new Error("API call failed: ".concat(api === null || api === void 0 ? void 0 : api.endpoint)), {
        extra: {
            args: {
                url: args === null || args === void 0 ? void 0 : args.url,
                method: args === null || args === void 0 ? void 0 : args.method,
                params: args === null || args === void 0 ? void 0 : args.params,
                body: args === null || args === void 0 ? void 0 : args.body,
            },
            api: {
                signal: api === null || api === void 0 ? void 0 : api.signal,
                endpoint: api === null || api === void 0 ? void 0 : api.endpoint,
                type: api === null || api === void 0 ? void 0 : api.type,
                forced: api === null || api === void 0 ? void 0 : api.forced,
            },
            result: {
                status: (_c = result === null || result === void 0 ? void 0 : result.error) === null || _c === void 0 ? void 0 : _c.status,
                data: (_d = result === null || result === void 0 ? void 0 : result.error) === null || _d === void 0 ? void 0 : _d.data,
                cause: (_f = (_e = result === null || result === void 0 ? void 0 : result.error) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.cause,
            },
            extraOptions: extraOptions || 'None',
            telegramUserId: (_g = WebApp.initDataUnsafe.user) === null || _g === void 0 ? void 0 : _g.id,
        },
    });
};
var showToast = function (message) {
    toast(message, {
        type: 'error',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        closeButton: false,
    });
};
