export var pt = {
    loginDescription: 'Conexões de alta qualidade no Telegram. Junta-te à comunidade de pessoas ambiciosas e bonitas.',
    create_profile_button: 'Criar perfil',
    login: 'Iniciar sessão',
    create_profile_title: 'Criar perfil',
    name_input_placeholder: 'O seu nome',
    description_input_placeholder: 'Sobre si',
    description_input_example: 'Exemplo: Designer do Dubai. Adoro surfar...',
    profile_creation_continue_button: 'Continuar',
    personal_info_title: 'Informações pessoais',
    date_of_birth: 'Data de nascimento',
    date_of_birth_choose_select: 'Escolha uma data',
    gender: 'Género',
    login_description: 'Conexões de alta qualidade no Telegram. Junta-te à comunidade de pessoas ambiciosas e bonitas.',
    select: 'Selecionar',
    location: 'Localização',
    city_input_title: 'A sua cidade',
    previous_city_input_title: 'Cidade anterior',
    looking_for_title: 'À procura de',
    age_range: 'Faixa etária',
    social_title: 'Social',
    optional: 'opcional',
    cancel: 'Cancelar',
    instagram_input_placeholder: 'instagram.com/username',
    tiktok_input_placeholder: 'tiktok.com/@username',
    add_profile_photos_title: 'Adicionar fotos de perfil',
    main_photo_title: 'Foto principal',
    at_least_3: 'Carregue pelo menos três fotos.',
    at_least_info: 'Suas fotos devem se concentrar em você—seu rosto e corpo. Certifique-se de que você é a única pessoa na imagem. Não envie imagens irrelevantes ou coisas aleatórias.',
    photo_upload_note: 'Pelo menos 3 fotos. Breve descrição dos tipos de fotos aceitáveis e restrições (por exemplo, sem nudez, deve mostrar claramente o rosto do utilizador).',
    x_of_y: '0 de 9',
    profile_under_review_title: 'O seu perfil está em análise',
    profile_under_review_info: 'Após a verificação, terás acesso a todos os benefícios de Queens:',
    benefit_1: 'Pontuação de compatibilidade personalizada',
    benefit_2: 'Obtenha recompensas pela qualidade do perfil',
    benefir_3: 'Todos os perfis são verificados',
    got_it: 'Entendido',
    quiz_complete_compatability: 'Complete o questionário de compatibilidade',
    quiz_description: 'Obtenha uma pontuação de compatibilidade personalizada com base nas respostas',
    start_quiz: 'Iniciar um questionário',
    zodiac: 'Zodíaco',
    tasks: 'Tarefas',
    refs: 'Referidos',
    social_tasks: 'Tarefas sociais',
    join_telegram_chanel_task_title: 'Junte-se ao canal TG de Queens',
    start_task: 'Iniciar',
    instagram_follow_task_title: 'Segue o Instagram da Queens',
    daily_tasks: 'Tarefas diárias',
    start_farming: 'Começar a cultivar',
    task_completion: 'Você tem X energia',
    refs_total_earnings: 'Ganhos totais',
    ref_first_level_earn_percent: '10% dos teus amigos',
    ref_second_level_earn_percent: '+2,5% dos seus referidos',
    invite_friend: 'Convidar amigo',
    invite: 'Convidar',
    invite_friend_title: 'Convide amigos e receba',
    invite_firned_info: 'Recebe 10% dos ganhos dos teus amigos e um bónus adicional de +2,5% dos amigos deles.',
    send_invite: 'Enviar convite',
    copy_invite_link: 'Copiar link',
    invited_friends: 'Amigos convidados',
    edit_profile: 'Editar perfil',
    looking_for: 'À procura de',
    contact_support: 'Contactar o suporte',
    faq: 'Perguntas Frequentes',
    privacy_policy: 'Política de privacidade',
    pause_profile: 'Pausar o meu perfil',
    mutual: 'Mútuo',
    inbox: 'Caixa de Entrada',
    go_to_chat: 'Ir para chat',
    unlock_inbox_profile: 'Desbloquear',
    profile_unlocked: 'Perfil Desbloqueado',
    open_profile: 'Abrir perfil',
    it_is_a_match: 'É uma combinação!',
    match_description: 'Tu e o Glen gostaram um do outro',
    maybe_later: 'Talvez mais tarde',
    matchTitle: 'É uma combinação!',
    matchInfo: 'Tu e {{name}} gostaram um do outro',
    goToChat: 'Ir para chat',
    maybeLater: 'Talvez mais tarde',
    crownMatchTitle: 'Coloca a coroa!',
    crownMatchInfo: 'Parabéns! Agora pode conversar com {{name}}',
    popularLocations: 'Localizações populares',
    searchResults: 'Resultados da pesquisa',
    mutualEmptyTitle: 'Conexões mútuas',
    inboxEmptyTitle: 'Reações da caixa de entrada',
    mutualEmptyInfo: 'Quando alguém enviar uma reação no teu perfil, iremos notificar-te',
    unlockProfileMsg: 'Perfil desbloqueado',
    unlock: 'Desbloq.',
    openProfile: 'Abrir perfil',
    youveGot: 'Tens',
    youGot: 'Você conseguiu',
    start: 'Iniciar',
    about: 'Sobre',
    claim: 'Reivindicação',
    checkBackLater: 'Volte mais tarde',
    noOne: 'Não há ninguém novo à tua volta',
    male: 'Masculino',
    female: 'Feminino',
    nonBinary: 'Não-Binário (outro)',
    claimReward: 'Reclamar recompensa',
    profilePaused: 'Perfil pausado',
    unpause: 'Retomar',
    profileUnpaused: 'O perfil não está em pausa',
    completed: 'Concluído',
    confirm: 'Confirmar',
    age_restrict: 'Você deve ter 18 anos ou mais para continuar.',
    updating: 'Atualizando perfil...',
    needToSubscribe: 'Você precisa se inscrever no canal',
    aries: 'Áries',
    taurus: 'Touro',
    gemini: 'Gémeos',
    cancer: 'Cancro',
    leo: 'Leo',
    virgo: 'Virgem',
    libra: 'Libra',
    scorpio: 'Escorpião',
    sagittarius: 'Sagitário',
    capricorn: 'Capricórnio',
    aquarius: 'Aquário',
    pisces: 'Peixes',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    question_1: 'O que é este aplicativo?',
    question_1_answer: 'Este é um aplicativo de namoro único integrado ao Telegram. Em vez do deslizar tradicional "Sim/Não", o aplicativo permite que você interaja usando várias reações que representam suas emoções e preferências.',
    question_2: 'Quais reações posso usar no aplicativo?',
    question_2_answer_1: 'O aplicativo oferece cinco tipos de reações, cada uma com um custo específico:',
    question_2_answer_2: 'Coroa – custa 1 diamante',
    question_2_answer_3: 'Like – custa 10 raios',
    question_2_answer_4: 'Fogo – custa 10 raios',
    question_2_answer_5: 'Riso – custa 1 raio',
    question_2_answer_6: 'Aperto de mão – custa 1 raio',
    question_2_answer_7: 'Você pode escolher qualquer uma dessas reações para interagir com outros usuários.',
    question_3: 'Como ganho e gasto diamantes e raios?',
    question_3_answer: 'Você pode ganhar diamantes e raios sendo ativo no aplicativo, completando certas tarefas e convidando amigos para se juntarem. Esses recursos são usados para enviar reações a outros usuários ou desbloquear funcionalidades adicionais.',
    question_4: 'Como converso com outros usuários?',
    question_4_answer: 'Nesta versão do aplicativo, não há chat integrado. No entanto, quando você recebe uma correspondência com outro usuário, o nome de usuário dele no Telegram será mostrado, permitindo iniciar uma conversa diretamente pelo Telegram.',
    question_5: 'O que acontece quando recebo reações positivas?',
    question_5_answer: 'Quando você recebe reações positivas como Coroa, Curtir ou Fogo, elas são exibidas na seção de reações do aplicativo. Você verá quem enviou a reação, e reações positivas mútuas serão mostradas em uma seção separada. Você pode iniciar uma conversa através do Telegram se ambos reagirem positivamente.',
    question_6: 'As reações neutras aparecem no aplicativo?',
    question_6_answer: 'Não, reações neutras como Riso e Aperto de mão não aparecem nesta versão do aplicativo. Somente reações positivas serão mostradas para você.',
    question_7: 'Preciso enviar fotos para me registrar?',
    question_7_answer: 'Sim, é necessário enviar pelo menos três fotos para completar seu registro. Essas fotos devem mostrar claramente seu rosto e atender às diretrizes de conteúdo do aplicativo.',
    question_8: 'Posso editar meu perfil após o registro?',
    question_8_answer: 'Sim, você pode editar as informações do seu perfil e fotos após o registro. Basta ir à seção de perfil e fazer as alterações necessárias.',
    question_9: 'Como funciona o sistema de indicações?',
    question_9_answer: 'Ao convidar amigos para se juntarem ao aplicativo, você pode ganhar diamantes. As recompensas são concedidas ao clicar no botão "Iniciar" na seção de indicações, e você receberá bônus para cada amigo que se cadastrar através do seu link.',
    question_10: 'Como funciona o sistema de moderação?',
    question_10_answer: 'Após o registro, seu perfil será enviado para moderação. Enquanto está sendo revisado, você pode continuar usando o aplicativo normalmente. Se seu perfil for aprovado, você não notará nenhuma mudança. Se for rejeitado, será solicitado que você o reenvie com modificações.',
    question_11: 'Como receberei notificações?',
    question_11_answer: 'Todas as notificações serão enviadas através do bot do Telegram vinculado à sua conta. Essas notificações informarão sobre novas correspondências, reações recebidas e outros eventos importantes no aplicativo.',
    effective_date: 'Data de Vigência: 20.08.2024',
    policy: 'POLÍTICA DE PRIVACIDADE',
    policy_1: 'Queens Dating, operado pela QUEENS-DATING LIMITED (nós, nosso ou Empresa), está comprometido em proteger sua privacidade e garantir a segurança de seus dados pessoais. Esta Política de Privacidade detalha como coletamos, usamos, compartilhamos e protegemos suas informações pessoais quando você usa o aplicativo Queens Dating (o App ou Serviço). Ao utilizar o Serviço, você concorda com esta Política de Privacidade e consente com a coleta, uso e divulgação de suas informações conforme descrito aqui.',
    policy_2: 'Entendemos a importância da sua privacidade e estamos dedicados a lidar com suas informações pessoais de maneira responsável e segura.',
    policy_3: '1. Informações que Coletamos',
    policy_4: 'Coletamos vários tipos de informações para fornecer e melhorar nosso Serviço, incluindo:',
    policy_5: '1.1 Informações Pessoais',
    policy_6: '- Dados de Perfil: Quando você cria uma conta, coletamos informações pessoais como nome, idade, gênero, endereço de e-mail, número de telefone, foto de perfil, interesses, orientação sexual e preferências de relacionamento.',
    policy_7: '- Dados de Localização: Coletamos informações de geolocalização para ajudá-lo a se conectar com usuários próximos. Isso inclui dados em tempo real do seu dispositivo móvel, se você permitir o acesso aos serviços de localização.',
    policy_8: '- Dados de Comunicação: Isso inclui quaisquer comunicações que você compartilhe através do Serviço, como mensagens, gravações de voz ou trocas de mídia com outros usuários.',
    policy_9: '1.2 Dados Comportamentais e de Uso',
    policy_10: 'Coletamos informações sobre como você interage com nosso App:',
    policy_11: '- Informações de Atividade: Isso inclui detalhes sobre os recursos que você usa, os usuários com quem interage e suas preferências dentro do App, como deslizamentos, correspondências e interações.',
    policy_12: '- Informações do Dispositivo: Podemos coletar detalhes sobre o seu dispositivo, incluindo modelo de hardware, sistema operacional, versão do aplicativo, identificadores do dispositivo como endereço IP, endereço MAC, tipo de navegador e informações da rede móvel.',
    policy_13: '1.3 Dados Coletados Automaticamente',
    policy_14: 'Podemos coletar automaticamente determinados dados, incluindo:',
    policy_15: '- Dados de Log: Quando você interage com nosso Serviço, coletamos registros do servidor que podem incluir seu endereço IP, a data e hora do uso, as páginas visitadas, falhas no aplicativo e outras atividades do sistema.',
    policy_16: '- Cookies e Tecnologias Similares: Usamos cookies e tecnologias de rastreamento semelhantes para analisar tendências, administrar o site, rastrear os movimentos dos usuários no site e coletar informações demográficas.',
    policy_17: '2. Como Usamos Suas Informações',
    policy_18: 'Usamos as informações que coletamos para diversos fins a fim de melhorar sua experiência no Queens Dating, incluindo:',
    policy_19: '- Para Fornecer o Serviço: Suas informações nos ajudam a fornecer, operar e manter nosso Serviço, incluindo conectá-lo com outros usuários com base em suas preferências e localização.',
    policy_20: '- Para Personalizar Sua Experiência: Usamos suas informações para personalizar sua experiência, como sugerir possíveis correspondências, exibir conteúdo e melhorar recomendações.',
    policy_21: '- Para Melhorar o Serviço: Analisamos os dados para entender as tendências e padrões de uso a fim de melhorar os recursos e a funcionalidade do nosso Serviço.',
    policy_22: '- Para Marketing e Promoções: Podemos usar suas informações pessoais para enviar conteúdos promocionais e anúncios relevantes para você, tanto dentro do App quanto por e-mail.',
    policy_23: '- Para Comunicar com Você: Podemos usar suas informações para enviar atualizações, notificações, alertas de segurança e mensagens de suporte.',
    policy_24: '- Para Garantir Segurança: Usamos informações para monitorar atividades dos usuários, detectar e prevenir fraudes e garantir conformidade com nossos termos e condições.',
    policy_25: '3. Compartilhamento de Suas Informações',
    policy_26: 'Valorizamos sua privacidade e só compartilhamos suas informações conforme descrito nesta política:',
    policy_27: '3.1 Com Outros Usuários',
    policy_28: 'Ao utilizar o Serviço, você concorda que podemos compartilhar suas informações de perfil, como nome, idade, fotos e outros dados de perfil com outros usuários. Por favor, tenha cuidado com as informações que você divulga, pois você é responsável pelo que compartilha com outros.',
    policy_29: '3.2 Com Fornecedores de Serviços',
    policy_30: 'Contratamos prestadores de serviços terceirizados de confiança para realizar determinadas funções comerciais, como serviços de hospedagem, análise de dados, suporte ao cliente, processamento de pagamentos e publicidade. Esses prestadores de serviços são obrigados a proteger suas informações e utilizá-las apenas para os fins que especificamos.',
    policy_31: '3.3 Em Caso de Transferência de Negócios',
    policy_32: 'Se passarmos por uma fusão, aquisição, reorganização ou venda de ativos, suas informações poderão ser transferidas como parte dessa transação. Nós o notificaremos sobre qualquer transferência e as novas práticas de privacidade que serão aplicadas.',
    policy_33: '3.4 Para Conformidade Legal',
    policy_34: 'Podemos compartilhar suas informações quando exigido por lei, como em resposta a uma intimação, investigação governamental ou outros processos legais. Também podemos compartilhar informações para proteger os direitos, propriedade ou segurança da Queens Dating, de nossos usuários ou de terceiros.',
    policy_35: '4. Transferências Internacionais de Dados',
    policy_36: 'Suas informações pessoais podem ser transferidas e mantidas em servidores localizados fora de sua jurisdição, incluindo países onde as leis de proteção de dados podem diferir das do seu país de origem. Ao usar nosso Serviço, você consente com essas transferências. Tomamos medidas para garantir que suas informações sejam tratadas de forma segura e de acordo com esta Política de Privacidade, onde quer que sejam processadas.',
    policy_37: '5. Retenção de Dados',
    policy_38: 'Reteremos suas informações pessoais apenas pelo tempo necessário para fornecer nosso Serviço e cumprir nossas obrigações legais. Se você encerrar sua conta, excluiremos ou anonimizaremos suas informações pessoais, a menos que a retenção seja exigida por lei ou para fins comerciais legítimos.',
    policy_39: '6. Seus Direitos e Escolhas',
    policy_40: 'Dependendo da sua localização e das leis aplicáveis, você pode ter os seguintes direitos:',
    policy_41: '- Acesso às Suas Informações: Você pode solicitar acesso aos seus dados pessoais e obter uma cópia das informações que possuímos sobre você.',
    policy_42: '- Correção de Informações: Você tem o direito de solicitar a correção de quaisquer informações imprecisas ou incompletas.',
    policy_43: '- Exclusão de Informações: Em determinadas circunstâncias, você pode solicitar que excluamos seus dados pessoais. Observe que podemos reter certas informações conforme exigido por lei.',
    policy_44: '- Restrição de Processamento: Você pode solicitar que restrinjamos o processamento de suas informações pessoais em determinadas situações.',
    policy_45: '- Portabilidade de Dados: Você tem o direito de solicitar a transferência de seus dados para outro provedor de serviços.',
    policy_46: '- Retirada de Consentimento: Quando dependemos do consentimento para processar seus dados, você tem o direito de retirar esse consentimento a qualquer momento.',
    policy_47: 'Para exercer qualquer um desses direitos, entre em contato conosco em [Inserir Informações de Contato].',
    policy_48: '7. Segurança dos Dados',
    policy_49: 'Estamos comprometidos em manter a segurança de suas informações. Implementamos uma variedade de medidas técnicas e organizacionais para proteger seus dados contra acesso, alteração, divulgação ou destruição não autorizados. No entanto, nenhuma medida de segurança é totalmente infalível, e não podemos garantir a segurança absoluta de suas informações.',
    policy_50: '8. Privacidade das Crianças',
    policy_51: 'Nosso Serviço não é destinado a indivíduos menores de 18 anos. Não coletamos intencionalmente informações pessoais de crianças menores de 18 anos. Se descobrirmos que coletamos inadvertidamente informações pessoais de uma criança, tomaremos medidas para excluir essas informações o mais rápido possível. Se você acredita que podemos ter informações de ou sobre uma criança, entre em contato conosco.',
    policy_52: '9. Alterações a Esta Política de Privacidade',
    policy_53: 'Podemos atualizar esta Política de Privacidade de tempos em tempos para refletir mudanças em nossas práticas ou exigências legais. Notificaremos você sobre quaisquer alterações materiais publicando a nova Política de Privacidade nesta página e atualizando a Data de Vigência no topo. Recomendamos que você revise esta Política de Privacidade periodicamente para verificar atualizações.',
    policy_54: '10. Entre em Contato Conosco',
    policy_55: 'Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, ou desejar exercer seus direitos, entre em contato conosco.',
};
