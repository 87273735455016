var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { convertEditProfile, convertOnboardingQuestions, } from '../../converter/general.converter';
import { EQueensUrls } from '../../urls/queens.urls';
import { determineLanguage, fetchTimezone, replaceToCDN, userId, } from '../../utils/general.utils';
import { pushToSentry } from '../../utils/sentry.utils';
var BackendError;
(function (BackendError) {
    BackendError["USER_IS_NOT_ONBOARDED"] = "USER_IS_NOT_ONBOARDED";
})(BackendError || (BackendError = {}));
export var queensUserApi = createApi({
    reducerPath: 'queensUserApi',
    tagTypes: ['user', 'swipes', 'refs'],
    baseQuery: function (args, api, extraOptions) { return __awaiter(void 0, void 0, void 0, function () {
        var backendUrl, baseQuery, id, separator, result;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    backendUrl = import.meta.env.VITE_BACKEND_URL;
                    baseQuery = fetchBaseQuery({
                        baseUrl: "".concat(backendUrl, "/").concat(EQueensUrls.USERS),
                        headers: {},
                    });
                    if (typeof args === 'string') {
                        args = "".concat(args, "?authToken=").concat(localStorage.getItem('authToken'));
                    }
                    else if (args.url) {
                        id = undefined;
                        if (!args.url.includes('details'))
                            id = localStorage.getItem('internalId');
                        separator = args.url.includes('?') ? '&' : '?';
                        args.url = "".concat(args.url).concat(separator, "authToken=").concat(localStorage.getItem('authToken'));
                    }
                    return [4 /*yield*/, baseQuery(args, api, extraOptions)];
                case 1:
                    result = _b.sent();
                    if (result.error) {
                        pushToSentry(args, api, extraOptions, result);
                        //@ts-ignore
                        switch ((_a = result.error.data) === null || _a === void 0 ? void 0 : _a.cause) {
                            case BackendError.USER_IS_NOT_ONBOARDED:
                                window.location.href = '/register';
                                break;
                        }
                    }
                    return [2 /*return*/, result];
            }
        });
    }); },
    endpoints: function (builder) { return ({
        unlockSocial: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.UNLOCK),
                    method: 'POST',
                    body: body,
                };
            },
        }),
        swipe: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.SWIPE),
                    method: 'POST',
                    body: body,
                };
            },
        }),
        startMining: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.PASSIVE_MINING, "/").concat(EQueensUrls.START),
                    method: 'POST',
                };
            },
        }),
        collectMining: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.PASSIVE_MINING, "/").concat(EQueensUrls.COLLECT),
                    method: 'POST',
                };
            },
        }),
        onboard: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.ONBOARD),
                    method: 'POST',
                    body: convertOnboardingQuestions(body),
                };
            },
            invalidatesTags: ['user'],
        }),
        seeMatches: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.MATCHES, "/").concat(EQueensUrls.SEE),
                    method: 'POST',
                };
            },
        }),
        getSwipes: builder.query({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.SWIPE, "/").concat(EQueensUrls.ITEMS),
                    method: 'GET',
                };
            },
            providesTags: ['swipes'],
        }),
        getUser: builder.query({
            query: function () {
                return {
                    url: "/".concat(userId, "/").concat(EQueensUrls.DETAILS),
                    method: 'GET',
                    params: {
                        language: determineLanguage(),
                    },
                };
            },
            //@ts-ignore
            transformResponse: function (data) {
                var _a;
                return __assign(__assign({}, data), { primaryProfilePicture: (data === null || data === void 0 ? void 0 : data.primaryProfilePicture)
                        ? replaceToCDN(data.primaryProfilePicture)
                        : '', pictureGallery: (data === null || data === void 0 ? void 0 : data.pictureGallery)
                        ? (_a = data === null || data === void 0 ? void 0 : data.pictureGallery) === null || _a === void 0 ? void 0 : _a.map(function (url) { return replaceToCDN(url); })
                        : [], timezone: data.latitude &&
                        data.longitude &&
                        fetchTimezone(data.latitude, data.longitude) });
            },
            providesTags: ['user'],
        }),
        getReferals: builder.query({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.REFERRALS, "?page=").concat(body.page),
                    method: 'GET',
                };
            },
            providesTags: ['refs'],
        }),
        updateProfile: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(EQueensUrls.PROFILE, "/").concat(body.userId),
                    method: 'PUT',
                    body: convertEditProfile(body),
                };
            },
            invalidatesTags: ['user'],
        }),
        updateLookingFor: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(EQueensUrls.LOOKING_FOR, "/").concat(body.userId),
                    method: 'PUT',
                    body: body,
                };
            },
            invalidatesTags: ['swipes'],
        }),
        pauseProfile: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.PAUSE),
                    method: 'POST',
                };
            },
        }),
        unPauseProfile: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.UNPAUSE),
                    method: 'POST',
                };
            },
        }),
        getMatches: builder.query({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.MATCHES, "?page=").concat(body.page),
                    method: 'GET',
                };
            },
        }),
        getInbox: builder.query({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.INBOX, "?page=").concat(body.page),
                    method: 'GET',
                };
            },
        }),
        unblur: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(body.id, "/").concat(EQueensUrls.UNBLUR),
                    method: 'POST',
                };
            },
        }),
        collectRefBonus: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(body.userId, "/").concat(EQueensUrls.REFERRALS, "/").concat(EQueensUrls.BONUS, "/").concat(EQueensUrls.COLLECT),
                    method: 'POST',
                };
            },
            invalidatesTags: ['refs'],
        }),
        goToChat: builder.mutation({
            query: function (body) {
                return {
                    url: "/".concat(EQueensUrls.GO_TO_CHAT),
                    body: body,
                    method: 'POST',
                };
            },
        }),
    }); },
});
export var useUnlockSocialMutation = queensUserApi.useUnlockSocialMutation, useSwipeMutation = queensUserApi.useSwipeMutation, useStartMiningMutation = queensUserApi.useStartMiningMutation, useCollectMiningMutation = queensUserApi.useCollectMiningMutation, useOnboardMutation = queensUserApi.useOnboardMutation, useSeeMatchesMutation = queensUserApi.useSeeMatchesMutation, useGetSwipesQuery = queensUserApi.useGetSwipesQuery, useGetUserQuery = queensUserApi.useGetUserQuery, useGetReferalsQuery = queensUserApi.useGetReferalsQuery, useUpdateProfileMutation = queensUserApi.useUpdateProfileMutation, useUpdateLookingForMutation = queensUserApi.useUpdateLookingForMutation, usePauseProfileMutation = queensUserApi.usePauseProfileMutation, useUnPauseProfileMutation = queensUserApi.useUnPauseProfileMutation, useGetMatchesQuery = queensUserApi.useGetMatchesQuery, useLazyGetMatchesQuery = queensUserApi.useLazyGetMatchesQuery, useLazyGetInboxQuery = queensUserApi.useLazyGetInboxQuery, useUnblurMutation = queensUserApi.useUnblurMutation, useCollectRefBonusMutation = queensUserApi.useCollectRefBonusMutation, useGoToChatMutation = queensUserApi.useGoToChatMutation;
