var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { popularLocations } from '../../data/general.data';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { fetchTimezone } from '../../utils/general.utils';
var GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
export default function GoogleMaps(_a) {
    var _this = this;
    var onChange = _a.onChange, inputValue = _a.inputValue, storeValue = _a.storeValue;
    var _b = React.useState([]), options = _b[0], setOptions = _b[1];
    var t = useTranslation().t;
    var fetch = React.useMemo(function () {
        return debounce(function (request, callback) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("https://".concat(import.meta.env.VITE_GOOGLE_ENDPOINT, "/maps/api/place/autocomplete/json"), {
                                params: {
                                    input: request.input,
                                    types: '(cities)',
                                    key: GOOGLE_MAPS_API_KEY,
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        callback(response.data.predictions || []);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching place predictions:', error_1);
                        callback([]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, 500);
    }, []);
    React.useEffect(function () {
        var active = true;
        fetch({ input: inputValue }, function (results) { return __awaiter(_this, void 0, void 0, function () {
            var newOptions;
            return __generator(this, function (_a) {
                if (active) {
                    newOptions = [];
                    if (results) {
                        newOptions = __spreadArray(__spreadArray([], newOptions, true), results, true);
                    }
                    setOptions(newOptions);
                }
                return [2 /*return*/];
            });
        }); });
        return function () {
            active = false;
        };
    }, [inputValue, fetch]);
    var getPlaceDetails = function (placeId) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, result, status_1, _b, lat, lng, cityName_1, countryName_1, timezoneData, error_2;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!placeId) {
                        console.error('Place ID is required');
                        return [2 /*return*/];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("https://".concat(import.meta.env.VITE_GOOGLE_ENDPOINT, "/maps/api/place/details/json"), {
                            params: {
                                place_id: placeId,
                                fields: 'geometry,address_components',
                                key: GOOGLE_MAPS_API_KEY,
                                language: 'en',
                            },
                        })];
                case 2:
                    response = _c.sent();
                    _a = response.data, result = _a.result, status_1 = _a.status;
                    if (status_1 === 'OK') {
                        if (result.geometry) {
                            _b = result.geometry.location, lat = _b.lat, lng = _b.lng;
                            cityName_1 = '';
                            countryName_1 = '';
                            result.address_components.forEach(function (component) {
                                if (component.types.includes('locality')) {
                                    cityName_1 = component.long_name;
                                }
                                if (component.types.includes('country')) {
                                    countryName_1 = component.long_name;
                                }
                            });
                            timezoneData = fetchTimezone(lat, lng);
                            onChange(cityName_1, countryName_1, lat, lng, timezoneData);
                        }
                    }
                    else {
                        console.error('Error fetching place details:', status_1);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _c.sent();
                    console.error('Error with the API request:', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { sx: { mx: '30px', mb: '10px', fontSize: '17px !important' }, className: 'karla-700' }, options.length ? t('searchResults') : t('popularLocations')),
        (options.length ? options : popularLocations).map(function (opt) { return (React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', sx: { mx: '30px', mb: '10px' }, onClick: function () { return getPlaceDetails(opt.place_id); }, key: opt.place_id },
            React.createElement(Box, null,
                React.createElement(Typography, { className: 'karla w-500', sx: { fontSize: '17px !important' } }, opt.structured_formatting.main_text),
                React.createElement(Typography, { className: 'karla', sx: {
                        fontSize: '15px !important',
                        WebkitTextFillColor: '#7A7A7A',
                    } }, opt.structured_formatting.secondary_text)),
            React.createElement(ChevronRightIcon, { sx: { fill: '#7A7A7A' } }))); })));
}
