import { Stack, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
var Onboarding = function (_a) {
    var children = _a.children, nextPage = _a.nextPage, _b = _a.onNext, onNext = _b === void 0 ? function () { } : _b, disabled = _a.disabled;
    var navigate = useNavigate();
    var handleContinue = function () {
        onNext();
        if (nextPage)
            navigate("/".concat(nextPage));
    };
    var t = useTranslation().t;
    return (React.createElement("div", { style: { height: '98%' } },
        React.createElement(Stack, { spacing: 2, alignItems: 'center', height: '85%', marginTop: '7%' }, children),
        React.createElement(Stack, null,
            React.createElement(Button, { disabled: disabled, variant: 'contained', className: 'register-btn sf-pro-semibold ', sx: {
                    borderRadius: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    height: '56px',
                    fontSize: '17px !important',
                    lineHeight: '20.29px',
                    letterSpacing: '1px',
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(43, 5, 5, 0.3) !important',
                    },
                }, onClick: handleContinue }, t('profile_creation_continue_button')))));
};
export default Onboarding;
