export var en = {
    loginDescription: 'High-quality connections in Telegram. Join the community of ambitious and beautiful people.',
    create_profile_button: 'Create profile',
    login: 'Login',
    create_profile_title: 'Create profile',
    name_input_placeholder: 'Your name',
    description_input_placeholder: 'About you',
    description_input_example: 'Example: Designer from Dubai. Love surfing...',
    profile_creation_continue_button: 'Continue',
    personal_info_title: 'Personal info',
    date_of_birth: 'Date of birth',
    date_of_birth_choose_select: 'Choose a date',
    gender: 'Gender',
    select: 'Select',
    location: 'Location',
    city_input_title: 'Your city',
    previous_city_input_title: 'Previous city',
    looking_for_title: 'Looking for',
    age_range: 'Age range',
    social_title: 'Social',
    optional: 'optional',
    cancel: 'Cancel',
    instagram_input_placeholder: 'instagram.com/username',
    tiktok_input_placeholder: 'tiktok.com/@username',
    add_profile_photos_title: 'Add profile photos',
    main_photo_title: 'Main photo',
    at_least_3: 'Upload at least three photos.',
    at_least_info: 'Your photos should focus on you—your face and body. Make sure you’re the only person in the picture. No irrelevant images or random stuff.',
    photo_upload_note: "At least 3 photos. Brief description of acceptable photo types and restrictions (e.g., no nudity, must clearly show the user's face).",
    x_of_y: '0 of 9',
    profile_under_review_title: 'Your profile is under review',
    profile_under_review_info: 'After verification, you will have access to all Queens benefits:',
    benefit_1: 'Personalized compatibility score',
    benefit_2: 'Get rewards for profile quality',
    benefir_3: 'All profiles are verified',
    got_it: 'Got it',
    quiz_complete_compatability: 'Complete compatibility quiz',
    quiz_description: 'Get personalized compatibility score based on answers',
    start_quiz: 'Start a quiz',
    zodiac: 'Zodiac',
    tasks: 'Tasks',
    refs: 'Refs',
    social_tasks: 'Social tasks',
    join_telegram_chanel_task_title: 'Join Queens TG channel',
    start_task: 'Start',
    instagram_follow_task_title: 'Follow Queens Instagram',
    daily_tasks: 'Daily tasks',
    start_farming: 'Start farming',
    task_completion: 'You got X energy',
    refs_total_earnings: 'Total earnings',
    ref_first_level_earn_percent: '10% of your friends',
    ref_second_level_earn_percent: '+2.5% from their referrals',
    invite_friend: 'Invite friend',
    invite: 'Invite',
    invite_friend_title: 'Invite friends and get ',
    invite_firned_info: 'Get 10% from your friends earnings and an additional bonus of +2.5% from their friends.',
    send_invite: 'Send invite',
    copy_invite_link: 'Copy link',
    invited_friends: 'Invited friends',
    edit_profile: 'Edit profile',
    looking_for: 'Looking for',
    contact_support: 'Contact support',
    faq: 'FAQ',
    privacy_policy: 'Privacy policy',
    pause_profile: 'Pause my profile',
    mutual: 'Mutual',
    inbox: 'Inbox',
    go_to_chat: 'Go to chat',
    unlock_inbox_profile: 'Unlock',
    profile_unlocked: 'Profile Unlocked',
    open_profile: 'Open profile',
    it_is_a_match: 'It’s a match!',
    match_description: 'You and Glen have liked each other',
    maybe_later: 'Maybe later',
    matchTitle: "It's a match!",
    matchInfo: 'You and {{name}} have liked each other',
    goToChat: 'Go to chat',
    maybeLater: 'Maybe later',
    crownMatchTitle: 'Put on the crown!',
    crownMatchInfo: 'Congrats! You can chat with {{name}} now',
    popularLocations: 'Popular locations',
    searchResults: 'Search results',
    mutualEmptyTitle: 'Mutual connections',
    inboxEmptyTitle: 'Inbox reactions',
    mutualEmptyInfo: 'When someone will send a reaction on your profile we will notify you',
    unlockProfileMsg: 'Profile unlocked',
    unlock: 'Unlock',
    openProfile: 'Open profile',
    youveGot: 'You’ve got',
    youGot: 'You got',
    start: 'Start',
    about: 'About',
    claim: 'Claim',
    checkBackLater: 'Check back later',
    noOne: 'There’s no one new around you',
    male: 'Male',
    female: 'Female',
    nonBinary: 'Non-Binary (other)',
    claimReward: 'Claim reward',
    profilePaused: 'Profile paused',
    unpause: 'Unpause',
    profileUnpaused: 'Profile is unpaused',
    completed: 'Completed',
    confirm: 'Confirm',
    age_restrict: 'You must be 18 or older to proceed.',
    needToSubscribe: 'You need to subscribe to channel',
    updating: 'Updating profile...',
    aries: 'Aries',
    taurus: 'Taurus',
    gemini: 'Gemini',
    cancer: 'Cancer',
    leo: 'Leo',
    virgo: 'Virgo',
    libra: 'Libra',
    scorpio: 'Scorpio',
    sagittarius: 'Sagittarius',
    capricorn: 'Capricorn',
    aquarius: 'Aquarius',
    pisces: 'Pisces',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    question_1: 'What is this app?',
    question_1_answer: "This is a unique dating app that is integrated with Telegram. Instead of the traditional 'Yes/No' swiping, the app allows you to interact using various reactions that represent your emotions and preferences.",
    question_2: 'What reactions can I use in the app?',
    question_2_answer: 'The app features five types of reactions, each with a specific cost: Crown – costs 1 diamond, Like – costs 10 lightning bolts, Fire – costs 10 lightning bolts, Laugh – costs 1 lightning bolt, Handshake – costs 1 lightning bolt. You can choose any of these reactions to interact with other users.',
    question_2_answer_1: 'The app features five types of reactions, each with a specific cost:',
    question_2_answer_2: 'Crown – costs 1 diamond',
    question_2_answer_3: 'Like – costs 10 lightning bolts',
    question_2_answer_4: 'Fire – costs 10 lightning bolts',
    question_2_answer_5: 'Laugh – costs 1 lightning bolt',
    question_2_answer_6: 'Handshake – costs 1 lightning bolt',
    question_2_answer_7: 'You can choose any of these reactions to interact with other users',
    question_3: 'How do I earn and spend diamonds and lightning bolts?',
    question_3_answer: 'You can earn diamonds and lightning bolts by being active in the app, completing certain tasks, and inviting friends to join the app. These resources are then used to send reactions to other users or unlock additional features.',
    question_4: 'How do I chat with other users?',
    question_4_answer: 'In this version of the app, there is no built-in chat. However, when you receive a match with another user, their Telegram username will be shown to you, allowing you to start a conversation directly through Telegram.',
    question_5: 'What happens when I receive positive reactions?',
    question_5_answer: 'When you receive positive reactions like Crown, Like, or Fire, they will be displayed in the reactions section of the app. You will see who sent the reaction, and mutual positive reactions will be displayed in a separate section. You can initiate a conversation through Telegram if you both react positively to each other.',
    question_6: 'Do neutral reactions appear in the app?',
    question_6_answer: 'No, neutral reactions like Laugh and Handshake do not appear in this version of the app. Only positive reactions will be shown to you.',
    question_7: 'Do I need to upload photos to register?',
    question_7_answer: 'Yes, you are required to upload at least three photos to complete your registration. These photos should clearly show your face and meet the app’s guidelines for content.',
    question_8: 'Can I edit my profile after registration?',
    question_8_answer: 'Yes, you can edit your profile information and photos after registration. Just go to the profile section, and you can make changes as needed.',
    question_9: 'How does the referral system work?',
    question_9_answer: "By inviting friends to join the app, you can earn diamonds. Rewards are given when you click the 'Start' button in the referrals section, and you’ll receive bonuses for each friend that signs up through your link.",
    question_10: 'How does the moderation system work?',
    question_10_answer: "After registering, your profile will be sent for moderation. While it's being reviewed, you can still use the app normally. If your profile is approved, you won’t notice any changes. If your profile is declined, you will be asked to resubmit it with modifications.",
    question_11: 'How will I receive notifications?',
    question_11_answer: 'All notifications will be sent through the Telegram bot linked to your account. These notifications will inform you about new matches, incoming reactions, and other important events in the app.',
    effective_date: 'Effective Date: 20.08.2024',
    policy: 'PRIVACY POLICY',
    policy_1: 'Queens Dating, operated by QUEENS-DATING LIMITED (we, us, our, or Company), is committed to safeguarding your privacy and ensuring the security of your personal data. This Privacy Policy details how we collect, use, share, and protect your personal information when you use the Queens Dating application (the App or Service). By using the Service, you agree to this Privacy Policy and consent to the collection, use, and disclosure of your information as described herein.',
    policy_2: 'We understand the importance of your privacy and are dedicated to handling your personal information responsibly and securely.',
    policy_3: '1. Information We Collect',
    policy_4: 'We collect various types of information to provide and improve our Service, including:',
    policy_5: '1.1 Personal Information',
    policy_6: '- Profile Data: When you create an account, we collect personal information such as your name, age, gender, email address, phone number, profile photo, interests, sexual orientation, and relationship preferences.',
    policy_7: '- Location Data: We collect geolocation information to help you connect with nearby users. This includes real-time data from your mobile device if you permit access to location services.',
    policy_8: '- Communication Data: This includes any communications you share through the Service, such as messages, voice recordings, or media exchanges with other users.',
    policy_9: '1.2 Behavioral and Usage Data',
    policy_10: 'We collect information on how you interact with our App:',
    policy_11: '- Activity Information: This includes details about the features you use, the users you interact with, and your preferences within the App such as swiping, matches, interactions.',
    policy_12: '- Device Information: We may collect details about your device, including hardware model, operating system, app version, device identifiers like IP address, MAC address, browser type, and mobile network information.',
    policy_13: '1.3 Automatically Collected Data',
    policy_14: 'We may automatically collect certain data, including:',
    policy_15: '- Log Data: When you interact with our Service, we collect server logs that may include your IP address, the date and time of your use, the pages visited, app crashes, and other system activity.',
    policy_16: "- Cookies and Similar Technologies: We use cookies and similar tracking technologies to analyze trends, administer the website, track users' movements around the website, and gather demographic information.",
    policy_17: '2. How We Use Your Information',
    policy_18: 'We use the information we collect for a variety of purposes to enhance your experience on Queens Dating, including:',
    policy_19: '- To Provide the Service: Your information helps us provide, operate, and maintain our Service, including connecting you with other users based on your preferences and location.',
    policy_20: '- To Personalize Your Experience: We use your information to personalize your experience, such as suggesting potential matches, displaying content, and improving recommendations.  ',
    policy_21: '- To Improve the Service: We analyze data to understand trends and usage patterns to enhance and improve the features and functionality of our Service.  ',
    policy_22: '- For Marketing and Promotions: We may use your personal information to deliver promotional content and advertising that is relevant to you, both within the App and via email.  ',
    policy_23: '- To Communicate with You: We may use your information to send you updates, notifications, security alerts, and support messages.  ',
    policy_24: '- To Ensure Safety and Security: We use information to monitor user activities, detect and prevent fraud, and ensure compliance with our terms and conditions.',
    policy_25: '3. Sharing Your Information',
    policy_26: 'We value your privacy and only share your information as described in this policy:',
    policy_27: '3.1 With Other Users  ',
    policy_28: 'By using the Service, you agree that we may share your profile information such as your name, age, photos, and other profile data with other users. Please be mindful of what information you disclose, as you are responsible for sharing it with others.',
    policy_29: '3.2 With Service Providers',
    policy_30: 'We engage trusted third-party service providers to perform certain business-related functions such as hosting services, data analysis, customer support, payment processing, and advertising. These providers are required to protect your information and only use it for the purposes we specify.',
    policy_31: '3.3 In the Event of a Business Transfer',
    policy_32: 'If we undergo a merger, acquisition, reorganization, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such transfer and the new privacy practices that will apply.',
    policy_33: '3.4 For Legal Compliance',
    policy_34: 'We may share your information when required by law, such as in response to a subpoena, government inquiry, or other legal processes. We may also share information to protect the rights, property, or safety of Queens Dating, our users, or others.',
    policy_35: '4. International Data Transfers',
    policy_36: 'Your personal information may be transferred to and maintained on servers located outside of your jurisdiction, including countries where data protection laws may differ from those in your home country. By using our Service, you consent to such transfers. We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy wherever it is processed.',
    policy_37: '5. Data Retention',
    policy_38: 'We will retain your personal information only for as long as necessary to provide you with our Service and to comply with our legal obligations. If you close your account, we will delete or anonymize your personal information unless retention is required by law or for legitimate business purposes.',
    policy_39: '6. Your Rights and Choices',
    policy_40: 'Depending on your location and applicable laws, you may have the following rights:',
    policy_41: '- Access to Your Information: You may request access to your personal data and obtain a copy of the information we hold about you.',
    policy_42: '- Correction of Information: You have the right to request the correction of any inaccurate or incomplete information.',
    policy_43: '- Deletion of Information: Under certain circumstances, you may request that we delete your personal data. Note that we may retain certain information as required by law.',
    policy_44: '- Restriction of Processing: You may request that we restrict the processing of your personal information in certain situations.',
    policy_45: '- Data Portability: You have the right to request the transfer of your data to another service provider.',
    policy_46: '- Withdrawal of Consent: Where we rely on consent to process your data, you have the right to withdraw that consent at any time.',
    policy_47: 'To exercise any of these rights, please contact us at [Insert Contact Information].',
    policy_48: '7. Data Security',
    policy_49: 'We are committed to maintaining the security of your information. We implement a variety of technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no security measures are entirely foolproof, and we cannot guarantee the absolute security of your information.',
    policy_50: '8. Children’s Privacy',
    policy_51: 'Our Service is not intended for individuals under the age of 18. We do not knowingly collect personally identifiable information from children under 18. If we discover that we have inadvertently collected personal information from a child, we will take steps to delete that information as soon as possible. If you believe that we might have any information from or about a child, please contact us.',
    policy_52: '9. Changes to This Privacy Policy',
    policy_53: 'We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new Privacy Policy on this page and updating the Effective Date at the top. We encourage you to review this Privacy Policy periodically for any updates.',
    policy_54: '10. Contact Us',
    policy_55: 'If you have any questions or concerns about this Privacy Policy, or wish to exercise your rights, please contact us. ',
};
