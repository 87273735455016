export var ESocials;
(function (ESocials) {
    ESocials["INSTAGRAM"] = "INSTAGRAM";
    ESocials["TIK_TOK"] = "TIK_TOK";
})(ESocials || (ESocials = {}));
export var EActionType;
(function (EActionType) {
    EActionType["CROWN"] = "CROWN";
    EActionType["LIKE"] = "LIKE";
    EActionType["FIRE"] = "FIRE";
    EActionType["LAUGH"] = "LAUGH";
    EActionType["HANDSHAKE"] = "HANDSHAKE";
})(EActionType || (EActionType = {}));
export var EGender;
(function (EGender) {
    EGender["MALE"] = "MALE";
    EGender["FEMALE"] = "FEMALE";
})(EGender || (EGender = {}));
export var ETaskStatus;
(function (ETaskStatus) {
    ETaskStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ETaskStatus["COMPLETED"] = "COMPLETED";
})(ETaskStatus || (ETaskStatus = {}));
export var ETaskType;
(function (ETaskType) {
    ETaskType["TELEGRAM_CHANEL_SUBSCRIPTION"] = "TELEGRAM_CHANEL_SUBSCRIPTION";
    ETaskType["INSTAGRAM_ACCOUNT_SUBSCRIPTION"] = "INSTAGRAM_ACCOUNT_SUBSCRIPTION";
    ETaskType["TWITTER_ACCOUNT_SUBSCRIPTION"] = "TWITTER_ACCOUNT_SUBSCRIPTION";
    ETaskType["OTHER"] = "OTHER";
})(ETaskType || (ETaskType = {}));
