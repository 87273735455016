export var es = {
    loginDescription: 'Conexiones de alta calidad en Telegram. Únete a la comunidad de personas ambiciosas y hermosas.',
    create_profile_button: 'Crear perfil',
    login: 'Iniciar sesión',
    create_profile_title: 'Crear perfil',
    name_input_placeholder: 'Tu nombre',
    description_input_placeholder: 'Sobre ti',
    description_input_example: 'Ejemplo: Diseñador de Dubái. Me encanta surfear...',
    profile_creation_continue_button: 'Continuar',
    personal_info_title: 'Información personal',
    date_of_birth: 'Fecha de nacimiento',
    date_of_birth_choose_select: 'Elige una fecha',
    gender: 'Género',
    login_description: 'Conexiones de alta calidad en Telegram. Únete a la comunidad de personas ambiciosas y hermosas.',
    select: 'Seleccionar',
    location: 'Ubicación',
    city_input_title: 'Tu ciudad',
    previous_city_input_title: 'Ciudad anterior',
    looking_for_title: 'Buscando',
    age_range: 'Rango de edad',
    social_title: 'Social',
    optional: 'opcional',
    cancel: 'Cancelar',
    instagram_input_placeholder: 'instagram.com/username',
    tiktok_input_placeholder: 'tiktok.com/@username',
    add_profile_photos_title: 'Agregar fotos de perfil',
    main_photo_title: 'Foto principal',
    at_least_3: 'Sube al menos tres fotos.',
    at_least_info: 'Tus fotos deben centrarse en ti: tu cara y cuerpo. Asegúrate de ser la única persona en la imagen. No subas imágenes irrelevantes o cosas al azar.',
    photo_upload_note: 'Al menos 3 fotos. Breve descripción de los tipos de fotos aceptables y restricciones (por ejemplo, sin desnudos, debe mostrar claramente la cara del usuario).',
    x_of_y: '0 de 9',
    profile_under_review_title: 'Tu perfil está en revisión',
    profile_under_review_info: 'Después de la verificación, tendrás acceso a todos los beneficios de Queens:',
    benefit_1: 'Puntuación de compatibilidad personalizada',
    benefit_2: 'Obtén recompensas por la calidad del perfil',
    benefir_3: 'Todos los perfiles están verificados',
    got_it: 'Entendido',
    quiz_complete_compatability: 'Completa el cuestionario de compatibilidad',
    quiz_description: 'Obtén una puntuación de compatibilidad personalizada basada en las respuestas',
    start_quiz: 'Iniciar un cuestionario',
    zodiac: 'Zodiaco',
    tasks: 'Tareas',
    refs: 'Referidos',
    social_tasks: 'Tareas sociales',
    join_telegram_chanel_task_title: 'Únete al canal de TG de Queens',
    start_task: 'Comenzar',
    instagram_follow_task_title: 'Sigue el Instagram de Queens',
    daily_tasks: 'Tareas diarias',
    start_farming: 'Comenzar a cultivar',
    task_completion: 'Has conseguido X energía',
    refs_total_earnings: 'Ganancias totales',
    ref_first_level_earn_percent: 'El 10 % de tus amigos',
    ref_second_level_earn_percent: '+2,5 % de sus referidos',
    invite_friend: 'Invitar a un amigo',
    invite: 'Invitar',
    invite_friend_title: 'Invita a tus amigos y obtén',
    invite_firned_info: 'Obtén un 10% de las ganancias de tus amigos y un bono adicional de +2.5% de las ganancias de los amigos de tus amigos.',
    send_invite: 'Enviar invitación',
    copy_invite_link: 'Copiar enlace',
    invited_friends: 'Amigos invitados',
    edit_profile: 'Editar perfil',
    looking_for: 'Buscando',
    contact_support: 'Contactar con soporte',
    faq: 'Preguntas Frecuentes',
    privacy_policy: 'Política de privacidad',
    pause_profile: 'Pausar mi perfil',
    mutual: 'Mutuo',
    inbox: 'Recibidos',
    go_to_chat: 'Ir a chat',
    unlock_inbox_profile: 'Abrir',
    profile_unlocked: 'Perfil desbloqueado',
    open_profile: 'Abrir perfil',
    it_is_a_match: '¡Es un match!',
    match_description: 'Tú y Glen os habéis gustado',
    maybe_later: 'Tal vez más tarde',
    matchTitle: '¡Es un match!',
    matchInfo: 'Tú y {{name}} os habéis gustado',
    goToChat: 'Ir al chat',
    maybeLater: 'Tal vez más tarde',
    crownMatchTitle: '¡Ponte la corona!',
    crownMatchInfo: '¡Felicidades! Ahora puedes chatear con {{name}}',
    popularLocations: 'Ubicaciones populares',
    searchResults: 'Resultados de búsqueda',
    mutualEmptyTitle: 'Conexiones mutuas',
    inboxEmptyTitle: 'Reacciones de la bandeja de entrada',
    mutualEmptyInfo: 'Cuando alguien envíe una reacción en tu perfil, te notificaremos.',
    unlockProfileMsg: 'Perfil desbloqueado',
    unlock: 'Abrir',
    openProfile: 'Abrir perfil',
    youveGot: 'Tienes',
    youGot: 'Tienes',
    start: 'Comenzar',
    about: 'Acerca de',
    claim: 'Reclamo',
    checkBackLater: 'Vuelve más tarde',
    noOne: 'No hay nadie nuevo a tu alrededor',
    male: 'Masculino',
    female: 'Femenino',
    nonBinary: 'No binario (otro)',
    claimReward: 'Reclamar recompensa',
    profilePaused: 'Perfil pausado',
    unpause: 'Reanudar',
    profileUnpaused: 'El perfil está reanudado',
    completed: 'Completado',
    age_restrict: 'Debes tener 18 años o más para continuar.',
    updating: 'Actualizando perfil...',
    needToSubscribe: 'Necesitas suscribirte al canal',
    confirm: 'Confirmar',
    aries: 'Aries',
    taurus: 'Tauro',
    gemini: 'Géminis',
    cancer: 'Cáncer',
    leo: 'Leo',
    virgo: 'Virgo',
    libra: 'Libra',
    scorpio: 'Escorpio',
    sagittarius: 'Sagitario',
    capricorn: 'Capricornio',
    aquarius: 'Acuario',
    pisces: 'Piscis',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    question_1: '¿Qué es esta aplicación?',
    question_1_answer: 'Esta es una aplicación de citas única que está integrada con Telegram. En lugar del deslizamiento tradicional "Sí/No", la aplicación te permite interactuar utilizando diversas reacciones que representan tus emociones y preferencias.',
    question_2: '¿Qué reacciones puedo usar en la aplicación?',
    question_2_answer: 'La aplicación presenta cinco tipos de reacciones, cada una con un costo específico: Corona – cuesta 1 diamante, Me gusta – cuesta 10 rayos, Fuego – cuesta 10 rayos, Risa – cuesta 1 rayo, Apretón de manos – cuesta 1 rayo. Puedes elegir cualquiera de estas reacciones para interactuar con otros usuarios.',
    question_3: '¿Cómo gano y gasto diamantes y rayos?',
    question_3_answer: 'Puedes ganar diamantes y rayos siendo activo en la aplicación, completando ciertas tareas e invitando a amigos a unirse. Estos recursos se utilizan para enviar reacciones a otros usuarios o desbloquear funciones adicionales.',
    question_4: '¿Cómo chateo con otros usuarios?',
    question_4_answer: 'En esta versión de la aplicación, no hay un chat integrado. Sin embargo, cuando recibes una coincidencia con otro usuario, se te mostrará su nombre de usuario de Telegram, permitiéndote iniciar una conversación directamente a través de Telegram.',
    question_5: '¿Qué sucede cuando recibo reacciones positivas?',
    question_5_answer: 'Cuando recibes reacciones positivas como Corona, Me gusta o Fuego, se mostrarán en la sección de reacciones de la aplicación. Verás quién envió la reacción, y las reacciones positivas mutuas se mostrarán en una sección separada. Puedes iniciar una conversación a través de Telegram si ambos reaccionan positivamente.',
    question_6: '¿Aparecen las reacciones neutras en la aplicación?',
    question_6_answer: 'No, las reacciones neutras como Risa y Apretón de manos no aparecen en esta versión de la aplicación. Solo se te mostrarán las reacciones positivas.',
    question_7: '¿Necesito subir fotos para registrarme?',
    question_7_answer: 'Sí, se requiere que subas al menos tres fotos para completar tu registro. Estas fotos deben mostrar claramente tu rostro y cumplir con las directrices de contenido de la aplicación.',
    question_8: '¿Puedo editar mi perfil después del registro?',
    question_8_answer: 'Sí, puedes editar la información de tu perfil y las fotos después del registro. Simplemente ve a la sección de perfil y realiza los cambios necesarios.',
    question_9: '¿Cómo funciona el sistema de referidos?',
    question_9_answer: 'Al invitar a amigos a unirse a la aplicación, puedes ganar diamantes. Las recompensas se otorgan al hacer clic en el botón "Iniciar" en la sección de referidos, y recibirás bonos por cada amigo que se registre a través de tu enlace.',
    question_10: '¿Cómo funciona el sistema de moderación?',
    question_10_answer: 'Después de registrarte, tu perfil será enviado para moderación. Mientras se revisa, puedes seguir usando la aplicación normalmente. Si tu perfil es aprobado, no notarás ningún cambio. Si es rechazado, se te pedirá que lo vuelvas a enviar con modificaciones.',
    question_11: '¿Cómo recibiré notificaciones?',
    question_11_answer: 'Todas las notificaciones se enviarán a través del bot de Telegram vinculado a tu cuenta. Estas notificaciones te informarán sobre nuevas coincidencias, reacciones entrantes y otros eventos importantes en la aplicación.',
    effective_date: 'Data de Vigência: 20.08.2024',
    policy: 'POLÍTICA DE PRIVACIDADE',
    policy_1: 'O Queens Dating, operado pela QUEENS-DATING LIMITED (nós, nos, nosso, ou Empresa), está comprometido em proteger sua privacidade e garantir a segurança dos seus dados pessoais. Esta Política de Privacidade detalha como coletamos, usamos, compartilhamos e protegemos suas informações pessoais quando você usa o aplicativo Queens Dating (o App ou Serviço). Ao utilizar o Serviço, você concorda com esta Política de Privacidade e consente com a coleta, uso e divulgação de suas informações conforme descrito aqui.',
    policy_2: 'Entendemos a importância da sua privacidade e estamos dedicados a tratar suas informações pessoais de forma responsável e segura.',
    policy_3: 'Informações que Coletamos',
    policy_4: 'Coletamos vários tipos de informações para fornecer e melhorar nosso Serviço, incluindo:',
    policy_5: '1.1 Informações Pessoais',
    policy_6: 'Dados do Perfil: Quando você cria uma conta, coletamos informações pessoais como seu nome, idade, gênero, endereço de e-mail, número de telefone, foto de perfil, interesses, orientação sexual e preferências de relacionamento.',
    policy_7: 'Dados de Localização: Coletamos informações de geolocalização para ajudar você a se conectar com usuários próximos. Isso inclui dados em tempo real do seu dispositivo móvel se você permitir acesso aos serviços de localização.',
    policy_8: 'Dados de Comunicação: Inclui qualquer comunicação que você compartilha através do Serviço, como mensagens, gravações de voz ou trocas de mídia com outros usuários.',
    policy_9: '1.2 Dados Comportamentais e de Uso',
    policy_10: 'Coletamos informações sobre como você interage com nosso App:',
    policy_11: 'Informações de Atividade: Inclui detalhes sobre os recursos que você usa, os usuários com os quais você interage e suas preferências dentro do App, como deslizar, correspondências, interações.',
    policy_12: 'Informações do Dispositivo: Podemos coletar detalhes sobre seu dispositivo, incluindo modelo de hardware, sistema operacional, versão do aplicativo, identificadores do dispositivo como IP, endereço MAC, tipo de navegador e informações sobre a rede móvel.',
    policy_13: '1.3 Dados Coletados Automaticamente',
    policy_14: 'Podemos coletar automaticamente certos dados, incluindo:',
    policy_15: 'Dados de Log: Quando você interage com nosso Serviço, coletamos logs do servidor que podem incluir seu IP, a data e a hora do uso, as páginas visitadas, falhas no aplicativo e outras atividades do sistema.',
    policy_16: 'Cookies e Tecnologias Similares: Usamos cookies e tecnologias de rastreamento semelhantes para analisar tendências, administrar o site, rastrear movimentos dos usuários ao redor do site e coletar informações demográficas.',
    policy_17: 'Como Usamos Suas Informações',
    policy_18: 'Usamos as informações que coletamos para uma variedade de propósitos para aprimorar sua experiência no Queens Dating, incluindo:',
    policy_19: 'Para Fornecer o Serviço: Suas informações ajudam-nos a fornecer, operar e manter nosso Serviço, incluindo conectar você com outros usuários com base em suas preferências e localização.',
    policy_20: 'Para Personalizar Sua Experiência: Usamos suas informações para personalizar sua experiência, como sugerir correspondências potenciais, exibir conteúdo e melhorar recomendações.',
    policy_21: 'Para Melhorar o Serviço: Analisamos dados para entender tendências e padrões de uso para aprimorar e melhorar os recursos e funcionalidades de nosso Serviço.',
    policy_22: 'Para Marketing e Promoções: Podemos usar suas informações pessoais para fornecer conteúdo promocional e publicidade relevante para você, tanto dentro do App quanto por e-mail.',
    policy_23: 'Para Comunicar com Você: Podemos usar suas informações para enviar atualizações, notificações, alertas de segurança e mensagens de suporte.',
    policy_24: 'Para Garantir Segurança e Proteção: Usamos informações para monitorar atividades dos usuários, detectar e prevenir fraudes e garantir a conformidade com nossos termos e condições.',
    policy_25: 'Compartilhamento de Suas Informações',
    policy_26: 'Valorizamos sua privacidade e só compartilhamos suas informações conforme descrito nesta política:',
    policy_27: '3.1 Com Outros Usuários',
    policy_28: 'Ao usar o Serviço, você concorda que podemos compartilhar suas informações de perfil, como nome, idade, fotos e outros dados de perfil com outros usuários. Por favor, tenha cuidado com as informações que você divulga, pois você é responsável por compartilhá-las com outros.',
    policy_29: '3.2 Com Prestadores de Serviços',
    policy_30: 'Engajamos prestadores de serviços terceirizados de confiança para realizar certas funções relacionadas aos negócios, como serviços de hospedagem, análise de dados, suporte ao cliente, processamento de pagamentos e publicidade. Esses prestadores são obrigados a proteger suas informações e usá-las apenas para os propósitos que especificamos.',
    policy_31: '3.3 Em Caso de Transferência de Negócios',
    policy_32: 'Se passarmos por uma fusão, aquisição, reorganização ou venda de ativos, suas informações podem ser transferidas como parte dessa transação. Notificaremos você sobre qualquer transferência desse tipo e as novas práticas de privacidade que se aplicarão.',
    policy_33: '3.4 Para Conformidade Legal',
    policy_34: 'Podemos compartilhar suas informações quando exigido por lei, como em resposta a uma intimação, investigação governamental ou outros processos legais. Também podemos compartilhar informações para proteger os direitos, propriedades ou segurança do Queens Dating, nossos usuários ou terceiros.',
    policy_35: 'Transferências Internacionais de Dados',
    policy_36: 'Suas informações pessoais podem ser transferidas e mantidas em servidores localizados fora de sua jurisdição, incluindo países onde as leis de proteção de dados podem diferir das do seu país de origem. Ao usar nosso Serviço, você consente com tais transferências. Tomamos medidas para garantir que suas informações sejam tratadas com segurança e de acordo com esta Política de Privacidade onde quer que sejam processadas.',
    policy_37: 'Retenção de Dados',
    policy_38: 'Manteremos suas informações pessoais apenas pelo tempo necessário para fornecer nosso Serviço e cumprir com nossas obrigações legais. Se você fechar sua conta, deletaremos ou anonimizaremos suas informações pessoais, a menos que a retenção seja exigida por lei ou para fins comerciais legítimos.',
    policy_39: 'Seus Direitos e Opções',
    policy_40: 'Dependendo de sua localização e das leis aplicáveis, você pode ter os seguintes direitos:',
    policy_41: 'Acesso às Suas Informações: Você pode solicitar acesso aos seus dados pessoais e obter uma cópia das informações que mantemos sobre você.',
    policy_42: 'Correção de Informações: Você tem o direito de solicitar a correção de quaisquer informações imprecisas ou incompletas.',
    policy_43: 'Exclusão de Informações: Em certas circunstâncias, você pode solicitar que excluamos seus dados pessoais. Observe que podemos reter certas informações conforme exigido por lei.',
    policy_44: 'Restrição de Processamento: Você pode solicitar que restrinjamos o processamento de suas informações pessoais em determinadas situações.',
    policy_45: 'Portabilidade de Dados: Você tem o direito de solicitar a transferência de seus dados para outro prestador de serviços.',
    policy_46: 'Retirada de Consentimento: Quando dependemos do consentimento para processar seus dados, você tem o direito de retirar esse consentimento a qualquer momento.',
    policy_47: 'Para exercer qualquer um desses direitos, entre em contato conosco em [Inserir Informações de Contato].',
    policy_48: 'Segurança dos Dados',
    policy_49: 'Estamos comprometidos em manter a segurança de suas informações. Implementamos uma variedade de medidas técnicas e organizacionais para proteger seus dados contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma medida de segurança é totalmente infalível, e não podemos garantir a segurança absoluta de suas informações.',
    policy_50: 'Privacidade das Crianças',
    policy_51: 'Nosso Serviço não é destinado a indivíduos com menos de 18 anos. Não coletamos conscientemente informações pessoalmente identificáveis de crianças menores de 18 anos. Se descobrirmos que coletamos inadvertidamente informações pessoais de uma criança, tomaremos medidas para excluir essas informações o mais rápido possível. Se você acreditar que podemos ter informações de ou sobre uma criança, entre em contato conosco.',
    policy_52: 'Alterações a Esta Política de Privacidade',
    policy_53: 'Podemos atualizar esta Política de Privacidade de tempos em tempos para refletir mudanças em nossas práticas ou exigências legais. Notificaremos você sobre quaisquer alterações materiais postando a nova Política de Privacidade nesta página e atualizando a Data de Vigência no topo. Encorajamos você a revisar esta Política de Privacidade periodicamente para quaisquer atualizações.',
    policy_54: 'Entre em Contato Conosco',
    policy_55: 'Se você tiver qualquer dúvida ou preocupação sobre esta Política de Privacidade, ou desejar exercer seus direitos, entre em contato conosco.',
};
